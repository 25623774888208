@import '~@sravni/design-system-theme/lib/scss/core/variables';

.cardTitle {
  font-size: 12px;
  line-height: 14px;

  @media screen and (min-width: $breakpoint-desktop) {
    font-size: 13px;
    line-height: 16px;
  }
}

.cardSubtitle {
  color: var(--color-D60);
}

.pictureContainer {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  height: 100%;

  img {
    display: block;
    height: 100%;
    width: auto;
  }

  &.mortgageInsurance,
  &.creditsRating {
    @media screen and (max-width: $breakpoint-tablet) {
      height: 80px;
    }
  }

  &.osago, &.credit {
    height: 115px;

    @media screen and (min-width: $breakpoint-tablet) {
      height: 120px;
    }
  }

  &.banner {
    height: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
      height: 140px;
    }
  }

  &.creditsRating {
    height: 54px;

    @media screen and (min-width: $breakpoint-tablet) {
      height: 75px;
    }

    @media screen and (min-width: $breakpoint-desktop) {
      height: 100px;
    }
  }

  &.sportInsurance {
    @media screen and (max-width: $breakpoint-tablet) {
      height: 75px;
    }
  }

  @media screen and (min-width: $breakpoint-tablet) {
    height: 100px;
  }
}

.iconWrapper {
  position: absolute;
  bottom: 12px;
  z-index: 1;
}

.badge {
  z-index: 1;
}