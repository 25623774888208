@import '~@sravni/design-system-theme/lib/scss/core/variables';

.layout {
  padding-top: 16px;

  @media screen and (min-width: $breakpoint-tablet) {
    padding-top: 24px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 32px;
  }
}

.currencies {
  flex-basis: 100%; 

  @media screen and (min-width: $breakpoint-tablet) {
    flex-basis: calc(50% - 12px); 
  }

  @media screen and (min-width: $breakpoint-desktop) {
    flex-basis: calc(100% / 3 - 16px); 
  }
}

.reviewBlock {
  flex-basis: 100%; 

  @media screen and (min-width: $breakpoint-tablet) {
    flex-basis: calc(50% - 12px); 
  }

  @media screen and (min-width: $breakpoint-desktop) {
    flex-basis: calc(100% / 3 - 16px); 
  }
}

.hotServicesBlock {
  flex-basis: 100%; 

  @media screen and (min-width: $breakpoint-tablet) {
    flex-basis: 100%; 
  }

  @media screen and (min-width: $breakpoint-desktop) {
    flex-basis: calc(100% / 3 - 16px); 
  }
}
