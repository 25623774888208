@import '~@sravni/design-system-theme/lib/scss/core/variables';

.link {
  color: var(--color-D100);

  @media screen and (min-width: $breakpoint-tablet) {
    padding: 8px 0;
  }
}

.card {
  padding: 12px 16px 12px;
  width: 100%;

  @media screen and (min-width: $breakpoint-tablet) {
    padding: 8px 24px 16px;
  }
}

.itemsWrapper {
  flex-direction: row;

  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.flagIcon {
  width: 28px;
  height: 28px;
  border-radius: 8px;
}

.currencyItem {
  &.up {
    color: var(--color-G100);
  }

  &.down {
    color: var(--color-R100);
  }
}

.colThird, .footerLink {
  @media screen and (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.skeleton {
  height: 56px;
  margin: 4px 0;

  @media screen and (min-width: $breakpoint-tablet) {
    height: 28px;
    margin: 12px 0;
  }
}

.divider {
  @media screen and (max-width: $breakpoint-tablet) {
    background-color: var(--color-L100);
  }
}
