.card {
  width: 100%;
  height: 100%;
  padding : 12px;
  position: relative;

  .crossIcon {
    color: var(--color-D40);
    cursor: pointer;

    &.primary {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}

.textContainer {
  width: fit-content;
  flex-grow: 1;
}

.hint {
  color: var(--color-D60);

  &-color-red {
    color: var(--color-R100);
  }

  &-color-blue {
    color: var(--color-B100);
  }
}

.description {
  color: var(--color-D100);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.linkButton {
  width: 100%;
}
