@import "~@sravni/design-system-theme/lib/scss/core/variables";

@keyframes preloaderAnimation {
  0% {
    right: 1000px;
  }
  100% {
    right: -1000px;
  }
}

.preloader-block {
  position: relative;
  background: var(--color-D06);

  background: linear-gradient(90deg, var(--color-D06) 25%, var(--color-L100) 37%, var(--color-D06) 63%);
  background-size: 400% 100%;
  animation: pulse 1.4s ease infinite;
}

@keyframes pulse {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -135% 0%;
  }
}

.preloader-polygon {
  @extend .preloader-block;

  width: 100%;
  height: 16px;

  border-radius: 16px;

  &--light {
    background: var(--color-L100);
    background: linear-gradient(90deg, var(--color-L100) 25%, var(--color-D06) 37%, var(--color-L100) 63%);
    background-size: 400% 100%;
    animation: pulse 1.4s ease infinite;
  }
}

.preloader-circle {
  @extend .preloader-block;

  width: 28px;
  height: 28px;

  border-radius: 50%;
}

@keyframes placeHolderShimmer {
  0% {
    right: 100%;
  }
  100% {
    right: -100%;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &[data-type='content'] {
    flex-wrap: wrap;
  }

  &[data-sub-type='news'] {
    height: 240px;

    @media screen and (max-width: $breakpoint-tablet) {
      height: auto;
    }
  }
}

.block {
  padding: 20px;
  border: 1px solid var(--color-D10);
  background: var(--color-L100);
  border-radius: 16px;

  &[data-type='offers'] {
    height: 234px;
  }

  &[data-type='content'] {
    height: 158px;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
      height: 117px;
      margin-bottom: 20px;
    }

    &:nth-child(4),
    &:last-child {
      @media screen and (max-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }

  &[data-amount='3'] {
    width: calc((100% - 40px) / 3);

    @media screen and (max-width: $breakpoint-tablet) {
      width: calc((100% - 30px) / 3);
    }
  }

  &[data-amount='4'] {
    width: calc((100% - 60px) / 4);
  }

  &[data-amount='5'] {
    width: calc((100% - 80px) / 5);

    @media screen and (max-width: $breakpoint-desktop) {
      width: calc((100% - 40px) / 3);
    }

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  &-container {
    position: relative;
    overflow: hidden;
    height: 100%;
    background: #f1f1f1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30%;
      right: 100%;
      background: linear-gradient(to right, #f1f1f1, #e7e7e7, #f1f1f1);
      animation-duration: 1.6s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: placeHolderShimmer;

      @media screen and (max-width: $breakpoint-tablet) {
        width: 40%;
        animation-duration: 1.3s;
      }
    }
  }

  &-white-block {
    position: absolute;
    background: var(--color-white);
    left: 0;
    right: 0;

    &[data-type='offers'] {
      &[data-pos='1'] {
        top: 0;
        left: 60%;
        right: 50px;
        height: 15px;
      }

      &[data-pos='2'] {
        right: 50px;
        top: 15px;
        height: 15px;
      }

      &[data-pos='3'] {
        left: 60%;
        right: 50px;
        top: 30px;
        height: 20px;
      }

      &[data-pos='4'] {
        top: 45px;
        height: 20px;
      }

      &[data-pos='5'] {
        top: 66px;
        height: 20px;
      }

      &[data-pos='6'] {
        left: 110px;
        right: 110px;
        bottom: 90px;
        height: 20px;
      }

      &[data-pos='7'] {
        height: 20px;
        bottom: 50px;
      }

      &[data-pos='8'] {
        left: 110px;
        right: 110px;
        bottom: 70px;
        height: 20px;
      }

      &[data-pos='9'] {
        bottom: 35px;
        height: 20px;
      }

      &[data-pos='10'] {
        bottom: 0;
        left: 110px;
        height: 35px;
      }
    }

    &[data-type='content'] {
      &[data-pos='1'] {
        top: 15px;
        height: 15px;
      }

      &[data-pos='2'] {
        top: 45px;
        height: 15px;

        @media screen and (max-width: $breakpoint-tablet) {
          height: 15px;
        }
      }

      &[data-pos='3'] {
        top: 75px;
        height: 26px;

        @media screen and (max-width: $breakpoint-tablet) {
          display: none;
        }
      }

      &[data-pos='4'] {
        bottom: 0;
        left: 40%;
        height: 15px;

        @media screen and (max-width: $breakpoint-tablet) {
          left: 30%;
        }
      }
    }
  }
}
