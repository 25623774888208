.carousel {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    z-index: -1;

    display: block;
    width: 30px;
    height: 100%;

    opacity: 0;
    transition: opacity 0.3s ease;

    content: '';
  }
}

.carousel__content {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;

  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
}

.item {
  display: block;
}
