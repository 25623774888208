@import "~@sravni/design-system-theme/lib/scss/core/variables";
@import "~@sravni/ugc/lib/theme/typography";

.wrapper {
  composes: wrapper from '../../styles/common.module.scss';

  margin-top: -20px;
  //padding-bottom: 24px;
  background-color: var(--color-L100);

  @media screen and (min-width: $breakpoint-desktop) {
    margin-top: -32px;
    padding-bottom: 0;
  }
}

.inner {
  composes: inner from '../../styles/common.module.scss';

  padding-top: 16px;

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 0;
  }
}

.header-link {
  display: flex;
  flex-grow: 1;
  max-width: 430px;

  @media screen and (min-width: $breakpoint-desktop) {
    display: block;
    max-width: none;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;

    text-align: left;
    letter-spacing: 0;
    text-transform: none;

    background-color: unset;
    border: none;
  }
}

.content {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
  overflow: hidden;

  border-radius: 16px 16px 0 0;

  background-color: var(--color-D10);

  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 24px;

    border-radius: 16px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    grid-column-gap: 1px;
    grid-template-rows: auto;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-flow: row;

    background-image: linear-gradient(0deg, var(--color-L100) 15%, rgba(230, 234, 239, 0) 21%, var(--color-D10) 40%, var(--color-D10) 60%, rgba(230, 234, 239, 0) 80%, var(--color-L100) 85%);
  }
}

.menu-item {
  position: relative;

  color: var(--color-D100);
  text-decoration: none;

  outline: none;

  &:hover {
    cursor: pointer;

    .menu-item__title {
      color: var(--color-B100);
    }

    .icon {
      color: var(--color-B100);
    }
  }

  &--mobile {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    padding: 20px 20px 20px 16px;

    background-color: var(--color-L100);
  }

  &--desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    padding: 23px 5px 11px;

    background-color: var(--color-L100);
  }

  &--modal {
    text-align: center;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  transition: color 0.3s ease;

  svg {
    width: 28px;
    height: 28px;
  }

  .menu-item--desktop &,
  .menu-item--mobile & {
    flex: 0 0 fit-content;
    width: 28px;
    height: 28px;
  }

  .menu-item--modal & {
    width: 60px;
    height: 60px;

    margin: 0 auto;
    padding: 12px;

    background-color: var(--color-D06);
    border-radius: 16px;
  }

  &--specialIcon svg {
    filter: none !important;
  }
}


.menu-item__title {
  width: min-content;

  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-D100);

  transition: color 0.3s;

  .menu-item--desktop & {
    display: flex;
    flex: 1;
    align-items: center;

    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .menu-item--modal & {
    position: relative;
    left: 50%;

    min-width: 60px;
    margin: 8px auto 0;

    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    transform: translateX(-50%);

    @media screen and (min-width: 375px) {
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.modal__header {
  margin-bottom: 20px;

  font-size: 18px;
  line-height: 22px;

  @media screen and (min-width: $breakpoint-tablet) {
    margin-bottom: 15px;
  }
}

.modal__section {
  & + & {
    margin-top: 32px;

    @media screen and (min-width: $breakpoint-tablet) {
      margin-top: 31px;
    }
  }
}

.modal__row {
  display: grid;
  grid-template-columns: repeat(4, 60px);
  row-gap: 20px;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(6, 60px);
  }
}

.dialog {
  width: 800px;
}