@import "~@sravni/design-system-theme/lib/scss/core/variables";

.content {
  composes: content from '../../styles/common.module.scss';

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }
}

.materials--hide {
  display: none;
}

.headingLink {
  text-decoration: none;
  color: var(--color-D100);
}

.tabsOuter {
  overflow: hidden;
  margin: 0 -16px;

  @media screen and (min-width: $breakpoint-tablet) {
    margin: 0;
  }
}

.tabsWrapper {
  margin: 0 0 -20px;
  padding: 0 16px 20px;
  overflow: auto;
}

.tabLink {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  color: var(--color-D100);
}

.tabLinkActive {
  color: var(--color-B100);
}