@import '~@sravni/design-system-theme/lib/scss/core/variables';

.gridContainer {
  align-self: flex-end;
}

.row {
  align-items: flex-end;
  justify-content: space-around;
  
  @media screen and (min-width: $breakpoint-desktop) {
    height: 264px;
  }
}

.col {
  
  @media screen and (max-width: $breakpoint-tablet) {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.title {
  white-space: pre;
  text-align: center;
  width: 104px;
}