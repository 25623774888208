.wrapper {
  height: 100%;
}

.verticalBarWrapper {
  height: 228px;
  margin-top: 36px;

  &.reverse {
    margin-top: 0;
  }
}

.barContainer {
  position: relative;
  display: flex;
  gap: 12px;
}

.horizontalBarContainer {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  
  &.reverse {
    flex-direction: row-reverse;
  }
}

.verticalBarContainer {
  height: 100%;
  align-items: flex-end;
  justify-content: center;

  &.reverse {
    align-items: flex-start;
  }
}

.bar {
  background-color: var(--color-B06);
  border: 1px solid var(--color-B30);
  border-radius: 12px;
}

.horizontalBar {
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: end;
  min-width: fit-content;
}

.verticalBar {
  display: flex;
  width: 36px;
  justify-content: center;
}

.blueLabelColor {
  color: var();
}

.label {
  padding: 12px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
}

.horizontalLabel {
  color: var(--color-B100);
}

.verticalLabel {
  position: absolute;
  color: var(--color-D100);
}

.value {
  color: var(--color-D100);
  text-wrap-mode: nowrap;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}

.skeleton {
  height: 36px;
}

.logo {
  position: absolute;
}