.container {
  background-color: var(--color-L100);

  &.whiteBackground {
    background-color: var(--color-D06);
  }
}

.auxillaryLayer {
  background-color: var(--color-D06);

  &.whiteBackground {
    background-color: var(--color-L100);
  }
}

.topBorders {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.bottomBorders {
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}
