@import "../../styles.module";

.creditsRating {
  grid-area: creditsRating
}

.subTitle {
  position: relative;
  display: block;
  max-width: none;
  z-index: 1;

  @media screen and (min-width: $breakpoint-desktop) {
    max-width: 68px;
  }
}