@import "../../styles.module";

.banner {
  grid-area: banner;
}

.card {
  background-color: #000;
}

.text {
  color: #fff;
  white-space: break-spaces;
}