.card {
  position: relative;
  border: 1px solid var(--color-B30);
  height: 100px;
}

.image {
  position: relative;
  top: -16px;
  right: -16px;
}

.link {
  flex-basis: calc(33.33333333% - 16px);
  flex-grow: 1;
  height: 100px;
}

.reviewCard {
  padding: 12px;
  height: 100%;
  position: relative;
  white-space: pre-line;
}

.reviewTitle {
  overflow: hidden;
  max-height: 36px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.reviewIcon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}