.layout {
  padding: 40px 0 0;
}

.cards {
  overflow: hidden;
  overflow-x: auto;
  padding-left: 16px;
  padding-right: 16px;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar {
    /* Chrome/Safari/Webkit */
    width: 0px;
    background: transparent;
  }
}

.badge {
  margin-top: 2px;
}
