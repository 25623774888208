@import '~@sravni/design-system-theme/lib/scss/core/variables';

.recentAction {
  grid-area: recentAction;
}


.wrapper {
  min-width: 150px;
  cursor: pointer;

  &.isMobile {
    width: 100%;
  }
}
