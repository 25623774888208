@import '~@sravni/design-system-theme/lib/scss/core/variables';

.card {
  height: 100%;
  min-height: 92px;
  min-width: 102px;
  position: relative;
  overflow: hidden;
  padding: 12px !important;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    filter: brightness(93%);
  }

  &:active {
    filter: brightness(80%);
    transform: scale(0.95);
  }

  @media screen and (min-width: $breakpoint-tablet) {
    min-width: 150px;
  }
}
