@import '~@sravni/design-system-theme/lib/scss/core/variables';

.content {
  composes: content from '../../../styles/common.module.scss';

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }
}

.content--mobile-slider {
  composes: content from '../../../styles/common.module.scss';

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: -20px;
  margin-left: -20px;
  padding-bottom: 25px;

  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::before,
  &::after {
    flex: 0 0 20px;
    content: '';
  }

  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
}

.block {
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  overflow: hidden;

  & + & {
    margin-top: 16px;
  }

  @media screen and (min-width: $breakpoint-tablet) {
    width: calc((100% - 16px) / 2);
    margin-bottom: 16px;

    &:last-child {
      display: flex;
    }

    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    & + & {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    width: calc((100% - 16px * 3) / 4);

    &:nth-last-child(3),
    &:nth-last-child(4) {
      margin-bottom: 0;
    }
  }

  &:hover {
    & .image {
      transform: scale(1.08, 1.08);
    }

    & .description::before {
      opacity: 0.5;
    }
  }

  &--small {
    border-top: 1px solid var(--color-D10);
    z-index: 99;

    .descriptionTitle {
      font-family: var(--font-open-sans);
      font-size: $font-size-l;
      line-height: $line-height-m;
      font-weight: 600;
    }

    .descriptionInfo {

      &:last-child {
        margin-bottom: 10px;
      }
    }

    & + & {
      margin-top: -12px;
      border-radius: 0 0 16px 16px;
      background-color: var(--color-L100);

      .description {
        padding-top: 12px;
      }
    }
  }
}

.imageWrapper {
  position: relative;

  display: block;
  flex: 0 0 147px;
  width: 100%;
  height: 147px;
  padding: 16px;

  border-radius: 12px 12px 0 0;
  will-change: transform;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-tablet) {
    flex: 0 0 140px;
    height: 140px;
    padding: 20px;
  }

  .block--mobile-slider & {
    @media screen and (max-width: $breakpoint-tablet - 1) {
      flex-basis: 97px;
      height: 97px;
    }
  }
}

.image {
  position: absolute;
  top: -1px;
  left: 0;

  display: block;
  width: 100%;
  height: calc(100% + 1px);

  object-fit: cover;
  transition: transform 0.5s ease;
}

.description {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 16px;

  text-decoration: none;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: color, opacity 0.3s ease;

    background-color: var(--color-L100);
    z-index: -1;
  }

  @media screen and (min-width: $breakpoint-tablet) {
    padding: 26px 20px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding: 14px 20px;
  }

  &:hover {
    color: var(--color-link-hover);
  }
}

.descriptionTitle {
  margin: 14px 0;
  color: var(--color-D100);
  font-family: var(--font-aeroport);
  font-weight: 500;
  font-size: $line-height-title-6;
  line-height: $line-height-xl;

  @media screen and (min-width: $breakpoint-tablet) {
    font-size: $font-size-xl;
    line-height: $line-height-xl;
  }

  img {
    display: none;
  }
}

.descriptionInfo {
  color: var(--color-D30);
  font-size: $font-size-s;
  line-height: 16px;
  white-space: nowrap;
}

.infoBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;

  svg {

    path {
      stroke-width: 1;
    }
  };
}

.descriptionRubric {
  margin-top: 12px;
}

.preloader {
  background-color: var(--color-L100);

  &__img-container {
    display: flex;

    background-color: var(--color-D06);

    & div:nth-of-type(1) ~ div {
      margin-left: 4px;
    }
  }

  &__body {
    padding: 20px;

    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
      margin-top: 6px;
    }

    &__bottom {
      display: flex;
      margin-top: 12px;

      div:last-of-type {
        position: relative;
        margin-left: 16px;

        &::before {
          position: absolute;
          top: 6px;
          left: -10px;
          display: block;
          width: 4px;
          height: 4px;

          border-radius: 2px;

          background-color: var(--color-D06);
          content: '';
        }
      }
    }
  }

  &--mobile .preloader {
    &__img-container {
      height: 97px;
      flex: 0 0 97px;
      padding: 16px;
    }

    &__body {
      padding: 16px;
    }
  }

  &--desktop .preloader {
    &__img-container {
      height: 132px;
      flex: 0 0 132px;
      padding: 20px;
    }
  }
}

.tags {
  position: relative;
  z-index: 1;
}
