@import '~@sravni/design-system-theme/lib/scss/core/variables';

.wrapper {
  min-width: 320px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }

  &[data-bg-white] {
    background-color: var(--color-L100);
  }

  &[data-bg-grey] {
    background-color: var(--color-D06);
  }
}

.whiteBackground {
  background-color: var(--color-L100);
}

.inner {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
  padding-top: 32px;

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 64px;
  }
}

.newInner {
  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 32px;
  }
}

.header-link {
  margin-top: 12px;
  margin-bottom: 12px;

  &[data-bottom-link] {
    margin-top: 35px;
  }
}

.content {
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.button {
  width: 120px;
  border-color: var(--color-D10) !important;
}

.pixel-image {
  display: block;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.universal-wrapper {
  --padding: 16px;

  min-width: 320px;
  width: 100%;
  max-width: calc(1248px + 2 * var(--padding));

  margin-left: auto;
  margin-right: auto;

  padding-left: var(--padding);
  padding-right: var(--padding);

  overflow: hidden;

  @media screen and (min-width: $breakpoint-tablet) {
    --padding: 30px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    --padding: 40px;
  }

  &--full-width-on-mobile {
    @media screen and (max-width: $breakpoint-desktop) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--last-deal {
    padding-top: 12px;
    background-color: var(--color-L100);
    max-width: 100%;
  }
}

.inviteWrapper {
  padding-bottom: 32px;

  @media screen and (min-width: $breakpoint-desktop) {
    padding-bottom: 64px;
    width: 100%;
  }
}
